export default {
    name: 'The Ramen',
    loading: 'Загрузка',
    langchanger: 'Установить язык страницы',
    closed: {
        info1: 'Сайт закрыт',
        info2: 'Скоро откроется'
    },
    offline: {
        info1: 'Сервер недоступен',
        info2: 'Пожалуйста, подождите, мы работаем над проблемой'
    },
    nav: {
        profile: 'Ваш аккаунт',
        admin: 'Администратор',
        logout: 'Выйти',
        login: 'Вход',
        registration: 'Регистрация',
        orders: 'Ваши заказы',
        staffOrders: 'Заказы персонала',
    },
    cover: {
        info1: 'Суши нарезанные для вас! Традиционные и фьюжн. Веганские, безглютеновые, фитнес-суши! Мы с удовольствием приготовим их для вас.',
        info2: 'Приглашаем также на десерты и специальные блюда.',
    },
    soupOfTheDay: {
        header: 'Блюдо дня',
    },
    seeMenu: {
        header: 'Суши - наша страсть',
        about: 'О ресторане',
        team: 'Почему The Ramen?',
        contact: 'Контакты',
    },
    aboutUs: {
        restaurant: 'О ресторане',
        virtual: 'Виртуальный тур',
        whyUs: {
            header: "Почему The Ramen?",
        },
    },
    contact: {
        header: 'Контакты',
        social: 'Присоединяйтесь к нам и следите за сезонными акциями',
        welcome: 'Добро пожаловать',
        apartment: 'Локация: {apartment}',
        floor: 'Этаж: {floor}',
        staircase: 'Подъезд: {staircase}',
        contact: 'Контакты',
        hours: 'Часы работы',
        closed: 'Закрыто',
    },
    menu: {
        header: 'Меню',
        closed: 'Сегодня заказы не принимаются.<br/>Приходите в рабочее время или оформите заказ на другую дату!',
        closedLong: 'К сожалению, ресторан сейчас закрыт. Приходите в ближайшее время.',
        adminClosed: 'Извините, у нас слишком много заказов. Попробуйте позже.',
        free_food: 'За каждые 100 злотых мы добавим вам бесплатно выбранный ролл :)',
        free_addons: 'Бесплатные дополнения',
        disabledDate: 'Заказ на выбранную дату невозможен. В случае проблем, пожалуйста, свяжитесь с нами по телефону.',
        differentMenuError: 'Вы пытаетесь добавить блюда из двух разных меню. Если вы хотите продолжить, ваш текущий заказ будет очищен.',
        todaySpecial: 'Сегодня действует специальное меню: {title}',
        specialMenuInfo: 'Действует на {date}',
        specialMenuActive: 'Мы приготовили для вас специальное меню! Проверьте!',
        specialMenuPerOrder: 'На каждый час есть ограничение на количество заказов, поэтому некоторые часы могут быть недоступны.',
        specialMenuFull: 'Извините. График заказов уже заполнен.',
        containPromoItems: 'Акция распространяется на продукты, заказанные на сегодня. В другие дни действует обычная цена.',
        mobile: {
            header: 'Выберите категорию',
            showDescription: 'Показать описание блюда',
            showAllDishes: 'Показать все блюда',
        },
    },
    cart: {
        addMore: 'Добавить в заказ',
        addMoreClose: 'Закрыть',
        validationFields: {
            name: "Имя получателя",
            email: 'Email',
            tel: 'Контактный телефон',
            payment_method: 'Способ оплаты',
            order_type: 'Способ получения заказа',
            address: 'Адрес доставки',
        },
        errors: {
            emptyFields: "Заполните все поля",
            deliveryNotPossible: "Невозможно доставить заказ",
            dateNotSelected: "Выберите время доставки",
            formData: "Отсутствующие поля: {missing}",
        },
    },
    order: {
        button: 'Заказать онлайн',
        phone: 'Тел: 502 75 66 66',
        header: 'Ваш заказ',
        version: 'Версия блюда',
        addons: 'Добавки',
        addToCart: 'Добавить в заказ',
        summary: 'Итог',
        sum: 'Сумма',
        orderPrice: 'Стоимость заказа',
        servicePrice: 'Сбор за обслуживание',
        discount: 'Скидка',
        togo_discount: "Скидка при личном самовывозе",
        deliveryPrice: 'Стоимость доставки',
        totalPrice: 'Общая сумма',
        address: 'Адрес доставки',
        addAddress: 'Добавить адрес',
        editAddress: 'Редактировать адрес',
        addDishComment: 'Добавить комментарий к блюду',
        dishComment: 'Комментарий к блюду',
        delivery_unavailable: 'Доставка недоступна для выбранного времени. Способ получения заказа изменен на: Самовывоз',
        data: {
            header: 'Информация о получателе',
            name: 'Имя получателя',
            email: 'Email',
            tel: 'Контактный телефон',
            peopleAmount: 'На сколько человек?',
            peopleAmountPlaceholder: 'На сколько человек? (по желанию)',
            nip: 'ИНН',
            nipPlaceholder: 'ИНН (по желанию)',
            comment: 'Комментарий к заказу',
        },
        delivery_when: {
            header: 'Доставка',
            modalHeader: 'Выберите дату и время доставки',
            modalHeaderCallendar: 'Выберите дату доставки',
            modalHeaderTime: 'Выберите время доставки',
            hourMainHeader: 'Час доставки',
            hourHoursHeader: 'Выберите час',
            hourMinutesHeader: 'Выберите минуту',
            changeDay: 'Изменить день',
            changeHour: 'Изменить час',
            now: 'Как можно скорее',
            select: 'Выберите дату',
            selectSpecial: 'Выберите время',
            specialMenuDay: 'В выбранный день действует специальное меню: {title}',
            specialMenuDayButton: 'Перейти к {title}',
            blocked: 'Заказ содержит блюда из временных категорий, которые доступны только в живых заказах',
        },
        payment_method: {
            header: 'Способ оплаты',
            cash: 'Наличными при получении',
            card: 'Картой при получении',
            tpay: 'Онлайн через tpay',
            pyszne_payment: 'Оплачено на pyszne.pl',
            uber_payment: 'Оплачено на Uber',
            glovo_payment: 'Оплачено на Glovo',
            wolt_payment: 'Оплачено на Wolt',
            only_points: 'Оплачено баллами',
            select_later: 'Выбрать позже (столик)',
        },
        order_type: {
            header: 'Способ получения заказа',
            online: 'Доставка',
            to_go: 'Самовывоз',
            to_go_rabat: 'Скидка {rabat}',
            in_restaurant: 'Поеду в ресторане',
        },
        create: 'Оформить заказ',
        success: 'Заказ успешно оформлен',
        payButton: {
            tpay: 'Оплатить заказ онлайн'
        },
        status: {
            created: 'Ожидание оплаты',
            queued: 'Ожидание подтверждения рестораном',
            in_preparation: 'Заказ принят. Ваше суши готовится!',
            ended: 'Заказ отправлен к вам или забран лично. Спасибо!',
            canceled: 'Заказ отменен',
            active_table: 'Активный столик',
            delivery_when: 'Заказ будет выполнен {date}',
        },
        shortStatus: {
            created: 'Ожидание оплаты',
            queued: 'В очереди',
            in_preparation: 'Готовится',
            ended: 'Завершено',
            canceled: 'Отменено',
            active_table: 'Столик',
        },
        index: {
            header: 'Ваши заказы'
        },
        estimated_delivery_time: 'Предполагаемое время доставки: {time}',
        estimated_to_go_time: 'Заказ будет готов к получению: {time}',
        promocode: {
            header: 'У меня есть промокод',
            button: 'Использовать',
            success: 'Промокод применен и будет использован при оформлении заказа',
            errors: {
                null: 'Промокод не существует',
                disabled: 'Промокод неактивен',
                used: 'Промокод уже был использован',
                ended: 'Срок действия промокода истек',
                min_price: 'Минимальная сумма заказа не достигнута',
                no_addon_table_end: 'Нельзя добавлять дополнения при закрытии столика',
                no_amount_table_start: 'Скидки начисляются при закрытии столика',
            }
        },
        error: {
            too_far: 'Адрес вне зоны доставки',
            min_price: 'Минимальная сумма заказа для этого расстояния составляет {min}zł',
            min_price_special: 'Минимальная сумма заказа для этого меню составляет {min}zł',
            free: 'Доставка бесплатна для заказов свыше {free}zł на данном расстоянии'
        },
    },
    timepicker: {
        no_hours: 'Нет доступных часов на выбранный день',
    },
    account: {
        header: 'Моя учетная запись',
        personalData: {
            header: 'Личные данные',
            password: {
                header: 'Изменение пароля',
                change: 'Изменить пароль',
                success: 'Пароль успешно изменен',
                info1: 'Надежный пароль - это основа безопасности вашей учетной записи.',
                info2: 'Вы можете в любой момент изменить свой пароль.',
            },
            data: {
                success: 'Данные получателя успешно установлены',
            },
            address: {
                success: 'Адрес доставки успешно установлен',
            },
            marketing: 'Согласия на маркетинг',
        },
    },
    invoice: {
        payment_method: {
            bank_transfer: 'Банковский перевод',
            cash: 'Наличные',
            online: 'Онлайн-оплата',
            card: 'Оплата картой',
        },
    },
    auth: {
        login: {
            header: 'Авторизация',
            button: 'Войти'
        },
        registration: {
            header: 'Регистрация',
            button: 'Зарегистрироваться',
        },
        forgotPassword: 'Не помню пароль',
        sendReset: {
            header: 'Сброс пароля',
            button: 'Отправить ссылку для сброса пароля'
        },
        reset: {
            header: 'Сброс пароля',
            button: 'Сбросить пароль'
        },
        side: {
            register: {
                header: 'Добро пожаловать на {site}',
                info1: 'На сайте {site} вы можете легко соревноваться за большие деньги.',
                info2: 'Заполните любой игровой купон.',
                info3: 'И все это, сидя удобно в кресле!'
            },
            sendReset: {
                header: 'Забыли пароль?',
                info1: 'Укажите адрес электронной почты, на котором зарегистрирована ваша учетная запись.',
                info2: 'На указанный адрес электронной почты будет отправлена ссылка для сброса пароля.'
            },
            reset: {
                header: 'Сброс пароля',
                info1: 'Вы начали процесс сброса пароля',
                info2: 'Введите новый пароль, чтобы снова войти на сайт.'
            }
        }
    },
    terms: {
        header: 'Условия использования',
    },
    privacy_policy: {
        header: 'Политика конфиденциальности',
    },
    deliveryPrice: {
        header: 'Стоимость доставки',
    },
    noGluten: {
        header: 'Суши без глютена',
    },
    catering: {
        header: 'Кейтеринг',
    },
    messages: {
        login: 'Успешный вход в систему!',
        logout: 'Успешный выход из системы!',
        signup: 'Учетная запись успешно создана!',
        error: 'Ошибка',
        mustLogin: 'Вы должны войти в систему',
        accessDenied: 'Доступ запрещен',
        onlyAdmin: 'Доступ только для администратора',
        banned: 'Вы были заблокированы',
    },
    days: ['понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота', 'воскресенье'],
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    form: {
        email: 'Email',
        password: 'Пароль',
        password2: 'Подтверждение пароля',
        passwordNew: 'Новый пароль',
        passwordCurrent: 'Текущий пароль',
        terms: 'Я подтверждаю, что принимаю <a href="/regulamin">правила</a> сервиса {site}',
        marketing: 'Я хочу получать информацию о акциях на свой email',
        errors: {
            fieldRequired: 'Обязательное поле',
            email: {
                wrong: 'Указан неверный email'
            },
            password: {
                min: 'Минимальная длина пароля - {min} символов',
                max: 'Максимальная длина пароля - {max} символов',
                notSame: 'Пароли не совпадают'
            },
            first_name: {
                min: 'Минимальная длина имени - {min} символов'
            },
            last_name: {
                min: 'Минимальная длина фамилии - {min} символов'
            },
            terms: 'Вы должны подтвердить правила'
        },
        save: 'Сохранить'
    },
    addressSelector: {
        header: 'Выберите адрес',
        info: 'Введите улицу и номер дома (без номера квартиры)',
        placeholder: 'Улица и номер дома',
        empty: 'Нет результатов, введите корректную улицу.',
        results: 'Найденные адреса (нажмите, чтобы выбрать)',
        apartment: 'Номер квартиры (по желанию)',
        floor: 'Этаж (по желанию)',
        staircase: 'Подъезд (по желанию)',
        button: 'Подтвердить',
    },
    promocode: {
        kind: {
            discount: 'Скидка (%)',
            addon: 'Бесплатный товар',
            amount: 'Скидка (сумма)',
            delivery: 'Бесплатная доставка',
        },
        max_use_amount: {
            once: 'Одноразовое использование',
            once_per_user: 'Одноразовое использование на аккаунт',
            infinite: 'Неограниченное',
        },
    },
    list: {
        showList: 'Показать список',
        show: 'Показать',
        id: 'ID',
        user: 'Пользователь',
        game: 'Игра',
        count: 'Количество',
        status: 'Статус',
        amount: 'Сумма',
        drawDate: 'Дата розыгрыша',
        date: 'Дата',
        price: 'Цена',
        prize: 'Приз',
        type: 'Тип',
        title: 'Заголовок',
        orderId: 'Номер заказа',
        address: 'Адрес доставки',
        filters: {
            header: 'Фильтры',
            button: 'Фильтровать',
            loading: 'Загрузка данных',
            empty: 'Нет результатов',
            status: 'Статус'
        },
        pagination: {
            prev: 'Предыдущая',
            next: 'Следующая',
            page: 'Страница',
            of: 'из {pageCount}'
        }
    },
    sayyes: 'Да',
    sayno: 'Нет',
    soon: 'Скоро',
}